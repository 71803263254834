import React from "react";
import { Link } from "react-router-dom";
import "../Styles/projects.css";
import sandwich from "../Images/sandwichHouse.gif";
import weatherAngular from "../Images/weather_ang.gif";
import deliveryFee from "../Images/delivery_fee.gif";
import todoList from "../Images/todo.gif";
import weatherApp from "../Images/weather_app.gif";

const Projects = () => {
  return (
    <div id="project" className="project-container">
      <div className="project-heading">
        <h2>Projects</h2>
      </div>
      <div className="card-container">
        {/* Project 1 */}
        <div className="project-card">
          <div className="card-content">
            <div className="project-thumbnail-frame">
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://sandwich-webshop.vercel.app/"
              >
                <img src={sandwich} alt="sanwichHouse Website" />
              </Link>
            </div>
            <div className="card-details">
              <h5>Sandwich House</h5>
              <div className="card-tech">
                <div className="tech-skill">
                  <p>React</p>
                </div>
                <div className="tech-skill">
                  <p>JavaScript</p>
                </div>
                <div className="tech-skill">
                  <p>CSS</p>
                </div>
              </div>
              <div className="card-description">
                <p>
                  This is a simple online ordering application for sandwiches
                  which allows users to browse through a wide array of sandwich
                  options, place orders, and cancel orders.
                </p>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://sandwich-webshop.vercel.app/"
            >
              View Live
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://github.com/kcmanoz/sandwich-webshop"
            >
              View Code
            </Link>
          </div>
        </div>

        {/* Project 2 */}
        <div className="project-card">
          <div className="card-content">
            <div className="project-thumbnail-frame">
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://delivery-fee-calculator-page.vercel.app/"
              >
                <img src={deliveryFee} alt="Delivery Fee application" />
              </Link>
            </div>
            <div className="card-details">
              <h5>Delivery Fee Calculator</h5>
              <div className="card-tech">
                <div className="tech-skill">
                  <p>React</p>
                </div>
                <div className="tech-skill">
                  <p>TypeScript</p>
                </div>
                <div className="tech-skill">
                  <p>CSS</p>
                </div>
              </div>
              <div className="card-description">
                <p>
                  Delivery fee calculator application built using React,
                  TypeScript and CSS that calculates the delivery fee for a
                  given order based on the cart value, delivery distance, the
                  amount of items ordered and day and time.
                </p>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://delivery-fee-calculator-page.vercel.app/"
            >
              View Live
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://github.com/kcmanoz/delivery-fee-calculator"
            >
              View Code
            </Link>
          </div>
        </div>

        {/* Project 3 */}
        <div className="project-card">
          <div className="card-content">
            <div className="project-thumbnail-frame">
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://weather-app-ang-project.vercel.app"
              >
                <img src={weatherAngular} alt="weather application" />
              </Link>
            </div>
            <div className="card-details">
              <h5>Weather Application</h5>
              <div className="card-tech">
                <div className="tech-skill">
                  <p>Angular</p>
                </div>
                <div className="tech-skill">
                  <p>TypeScript</p>
                </div>
                <div className="tech-skill">
                  <p>OpenWeatherMap API</p>
                </div>
                <div className="tech-skill">
                  <p>SCSS</p>
                </div>
              </div>
              <div className="card-description">
                <p>
                  This Weather Application is an Angular-based web application
                  that allows users to search for weather information by city
                  name. The application uses the OpenWeatherMap API to fetch
                  weather data. Users can view detailed weather information,
                  including temperature, wind speed, pressure, and humidity, in
                  either metric or imperial units.
                </p>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://weather-app-ang-project.vercel.app"
            >
              View Live
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://github.com/kcmanoz/weather_application"
            >
              View Code
            </Link>
          </div>
        </div>

        {/* Project 4 */}
        <div className="project-card">
          <div className="card-content">
            <div className="project-thumbnail-frame">
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://todo-list-page.vercel.app/"
              >
                <img src={todoList} alt="todo application" />
              </Link>
            </div>
            <div className="card-details">
              <h5>ToDo List Application</h5>
              <div className="card-tech">
                <div className="tech-skill">
                  <p>React</p>
                </div>
                <div className="tech-skill">
                  <p>JavaScript</p>
                </div>
                <div className="tech-skill">
                  <p>CSS</p>
                </div>
              </div>
              <div className="card-description">
                <p>
                  This simple web application is a classic "TODO List"
                  application, where users can create a list of tasks, edit
                  them, and delete them as needed. It's built using HTML, CSS,
                  and JavaScript, and leverages localStorage for persisting the
                  tasks data.
                </p>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://todo-list-page.vercel.app/"
            >
              View Live
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://github.com/kcmanoz/TodoList"
            >
              View Code
            </Link>
          </div>
        </div>

        {/* Project 5 */}
        <div className="project-card">
          <div className="card-content">
            <div className="project-thumbnail-frame">
              <Link
                target="_blank"
                rel="noreferrer"
                to="https://weather-application-info.vercel.app/"
              >
                <img src={weatherApp} alt="deliveryFee Website" />
              </Link>
            </div>
            <div className="card-details">
              <h5>Weather Application</h5>
              <div className="card-tech">
                <div className="tech-skill">
                  <p>JavaScript</p>
                </div>
                <div className="tech-skill">
                  <p>OpenWeatherMap API</p>
                </div>
                <div className="tech-skill">
                  <p>HTML</p>
                </div>
                <div className="tech-skill">
                  <p>CSS</p>
                </div>
              </div>
              <div className="card-description">
                <p>
                  This Weather Application is a responsive web application that
                  provides essential weather details such as temperature,
                  humidity, wind speed, and a brief description of the weather
                  condition.
                </p>
              </div>
            </div>
          </div>
          <div className="card-buttons">
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://weather-application-info.vercel.app/"
            >
              View Live
            </Link>
            <Link
              target="_blank"
              rel="noreferrer"
              to="https://github.com/kcmanoz/weatherApplication"
            >
              View Code
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
